var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.planForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.trans("plan.name")) + " ")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.planForm.name,
                        expression: "planForm.name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "name",
                      placeholder: _vm.trans("plan.name")
                    },
                    domProps: { value: _vm.planForm.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.planForm, "name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: { "form-name": _vm.planForm, "prop-name": "name" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.trans("plan.code")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.planForm.code,
                        expression: "planForm.code"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "code",
                      placeholder: _vm.trans("plan.code")
                    },
                    domProps: { value: _vm.planForm.code },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.planForm, "code", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: { "form-name": _vm.planForm, "prop-name": "code" }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.trans("plan.max_student")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.planForm.max_student,
                        expression: "planForm.max_student"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      name: "max_student",
                      placeholder: _vm.trans("plan.max_student")
                    },
                    domProps: { value: _vm.planForm.max_student },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.planForm,
                          "max_student",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.planForm,
                      "prop-name": "max_student"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("switches", {
                    staticClass: "m-l-20",
                    attrs: { theme: "bootstrap", color: "success" },
                    model: {
                      value: _vm.planForm.is_active,
                      callback: function($$v) {
                        _vm.$set(_vm.planForm, "is_active", $$v)
                      },
                      expression: "planForm.is_active"
                    }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.trans("plan.status")) +
                      "\n                        "
                  ),
                  _c("show-tip", {
                    attrs: { type: "field", module: "plan", tip: "tip_status" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("switches", {
                    staticClass: "m-l-20",
                    attrs: { theme: "bootstrap", color: "success" },
                    model: {
                      value: _vm.planForm.is_default,
                      callback: function($$v) {
                        _vm.$set(_vm.planForm, "is_default", $$v)
                      },
                      expression: "planForm.is_default"
                    }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.trans("plan.default")) +
                      "\n                        "
                  ),
                  _c("show-tip", {
                    attrs: { type: "field", module: "plan", tip: "tip_default" }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("switches", {
                    staticClass: "m-l-20",
                    attrs: { theme: "bootstrap", color: "success" },
                    model: {
                      value: _vm.planForm.is_featured,
                      callback: function($$v) {
                        _vm.$set(_vm.planForm, "is_featured", $$v)
                      },
                      expression: "planForm.is_featured"
                    }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.trans("plan.featured")) +
                      "\n                        "
                  ),
                  _c("show-tip", {
                    attrs: {
                      type: "field",
                      module: "plan",
                      tip: "tip_featured"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("plan.description")))
              ]),
              _vm._v(" "),
              _c("autosize-textarea", {
                attrs: {
                  rows: "2",
                  name: "description",
                  placeholder: _vm.trans("plan.description")
                },
                model: {
                  value: _vm.planForm.description,
                  callback: function($$v) {
                    _vm.$set(_vm.planForm, "description", $$v)
                  },
                  expression: "planForm.description"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.planForm, "prop-name": "description" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-sm-6" },
          _vm._l(_vm.planForm.plan_prices, function(plan_price) {
            return _c("div", { staticClass: "m-t-10" }, [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    plan_price.currency.name +
                      "(" +
                      plan_price.currency.symbol +
                      ")"
                  )
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(plan_price.frequencies, function(frequency) {
                  return _c(
                    "div",
                    { staticClass: "col-12 col-sm-6" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(frequency.payment_frequency.name))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: frequency.amount,
                            expression: "frequency.amount"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          name: _vm.getAmountFieldName(plan_price, frequency),
                          placeholder: _vm.trans("plan.amount")
                        },
                        domProps: { value: frequency.amount },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(frequency, "amount", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.planForm,
                          "prop-name": _vm.getAmountFieldName(
                            plan_price,
                            frequency
                          )
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light pull-right",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right m-r-10",
          attrs: { to: "/plan" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }