var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "left-sidebar" }, [
    _c("div", { staticClass: "scroll-sidebar" }, [
      _c("div", { staticClass: "user-profile" }, [
        _c("div", { staticClass: "profile-img" }, [
          _c("img", { attrs: { src: _vm.getAuthUser("avatar"), alt: "user" } })
        ])
      ]),
      _vm._v(" "),
      _c("nav", { staticClass: "sidebar-nav m-t-20" }, [
        _vm.getConfig("maintenance_mode")
          ? _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "badge badge-danger m-b-10" }, [
                _vm._v(_vm._s(_vm.trans("configuration.under_maintenance")))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.getConfig("mode")
          ? _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "badge badge-danger m-b-10" }, [
                _vm._v(_vm._s(_vm.trans("configuration.test_mode")))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("ul", { attrs: { id: "sidebarnav" } }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/home", exact: "" } }, [
                _c("i", { staticClass: "fas fa-home fa-fw" }),
                _vm._v(" "),
                _c("span", { staticClass: "hide-menu" }, [
                  _vm._v(_vm._s(_vm.trans("general.home")))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/user", exact: "" } }, [
                _c("i", { staticClass: "fas fa-users fa-fw" }),
                _vm._v(" "),
                _c("span", { staticClass: "hide-menu" }, [
                  _vm._v(_vm._s(_vm.trans("user.user")))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasPermission("access-configuration")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/currency", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-money-bill-alt fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("currency.currency")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("access-configuration")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/plan", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-boxes fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("plan.plan")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("access-configuration")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/instance", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-box fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("instance.instance")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("access-configuration")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/subscription", exact: "" } },
                    [
                      _c("i", { staticClass: "fas fa-life-ring fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("subscription.subscription")))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("access-configuration")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/subscriber", exact: "" } },
                    [
                      _c("i", { staticClass: "fas fa-bullhorn fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("subscriber.subscriber")))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("access-configuration")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/query", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-question-circle fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("query.query")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sidebar-footer" },
      [
        _vm.hasPermission("access-configuration")
          ? _c(
              "router-link",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.trans("configuration.configuration"),
                    expression: "trans('configuration.configuration')"
                  }
                ],
                staticClass: "link",
                attrs: { to: "/configuration" }
              },
              [_c("i", { staticClass: "fas fa-cogs" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("user.profile"),
                expression: "trans('user.profile')"
              }
            ],
            staticClass: "link",
            attrs: { to: "/profile" }
          },
          [_c("i", { staticClass: "fas fa-user" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("auth.logout"),
                expression: "trans('auth.logout')"
              }
            ],
            staticClass: "link",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.logout($event)
              }
            }
          },
          [_c("i", { staticClass: "fas fa-power-off" })]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }