import { render, staticRenderFns } from "./form.vue?vue&type=template&id=4015af6a&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\ems\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4015af6a')) {
      api.createRecord('4015af6a', component.options)
    } else {
      api.reload('4015af6a', component.options)
    }
    module.hot.accept("./form.vue?vue&type=template&id=4015af6a&", function () {
      api.rerender('4015af6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/message/form.vue"
export default component.exports